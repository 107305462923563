//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  inheritAttrs: false,
  props: {
    price: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    org: {
      type: [Number, String],
      default: () => 0
    },
    pos: {
      type: [Number, String],
      default: () => 0
    },
    at: {
      type: [Number, String],
      default: () => 0
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    w: {
      type: Number,
      default: 0
    },
    country: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      itemInfo: {
        featured: this.elements?.featured ? this.elements.featured : '0',
        url: this.$router.currentRoute.fullPath,
        coupon: this.price?.discount_price ? this.price.discount_price : '',
        price: this.price
      },
      trackEvent: this.price.discount_price?.end_date
        ? 'Con cupón y fecha'
        : this.price.discount_price
          ? 'Con cupón'
          : 'Sin cupón',
      store:
        this.$store.state.storesLoaded &&
        this.$store.state.stores.find(
          (store) => store.id === this.price.store?.id
        ),
      gtmData: {}
    }
  },
  beforeCreate () {
    if (process.client && !this.$store.state.storesLoaded) {
      this.$store.dispatch('loadStores').then(() => {
        this.store = this.$store.state.stores.find(
          (store) => store.id === this.price.store?.id
        )
      })
    }
  },
  beforeMount () {
    // let features = {}
    // this.price?.features.forEach((feature) => {
    //   let featureSalida = {}
    //   for (let i = 0; i < feature.values.length; i++) {
    //     featureSalida = { ...featureSalida, [`${feature.id}${i}`]: feature.values[i] }
    //   }
    //   features = { ...features, ...featureSalida }
    // })
    this.gtmData = {
      amp: this.$isAMP,
      at: this.at,
      item_brand_id: this.price.brand?.content_id,
      item_brand: this.price.brand?.name,
      item_id: this.price.product?.content_id,
      item_name: this.price.product?.name,
      item_type_id: this.price.product?.type_id,
      item_type_name: this.price.product?.type_name,
      link: this.price?.link.url,
      org: this.org,
      pos: this.pos,
      price_coupon: this.price?.discount_price ? 'true' : 'false',
      price_discount: this.price?.discount ? 'true' : 'false',
      price_id: this.price.content_id,
      price: this.price?.price,
      store_id: this.price.store?.content_id,
      store_name: this.price.store?.name
      // ...features,
    }
  },
  methods: {
    getPrice () {
      const price = this.price.discount_price
        ? this.price.discount_price.price
        : this.price.price
      return price
    },
    getRedirect () {
      global.methods.setPriceCardClickEvent(this.trackEvent, this.itemInfo)
      const routeData = this.$router.resolve(global.methods.setPriceLink(this.price, this.pos, this.org, this.at))
      window.open(routeData.href, '_blank')
    }
  }
}
